import React, { useState, useEffect } from "react";

import Login from "../Login";
import Dashboard from "../Dashboard";
import VpnUsers from "../Vpn";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userChoice, setUserChoice] = useState("dashboard");

  useEffect(() => {
    setIsAuthenticated(JSON.parse(localStorage.getItem("is_authenticated")));
  }, []);

  return (
    <>
      <h1 style={{ textAlign: "center", paddingTop: "20px" }}>
        Ostora Management Dashboard
      </h1>
      {isAuthenticated ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              paddingTop: "20px",
            }}
          >
            <button onClick={() => setUserChoice("dashboard")}>
              Gizmo Users
            </button>
            {/*<button onClick={() => setUserChoice("vpnUsers")}>VPN Users</button>*/}
          </div>
          {userChoice === "dashboard" ? (
            <Dashboard setIsAuthenticated={setIsAuthenticated} />
          ) : (
            <VpnUsers setIsAuthenticated={setIsAuthenticated} />
          )}
        </>
      ) : (
        <Login setIsAuthenticated={setIsAuthenticated} />
      )}
    </>
  );
};

export default App;
